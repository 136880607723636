import React, { Fragment, useState, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { withFirebase } from '../Firebase'
import Modal from '../Modal'

const CompanySignUp = props => {
  const recaptchaRef = useRef(null)
  const [error, setError] = useState({ message: '' })
  const [formData, setFormData] = useState({
    company: '',
    registration: '',
    business: '',
    address: '',
    area: '',
    province: '',
    contact: '',
    email: '',
  })
  const [showModal, setShowModal] = useState(false)
  const [verified, setVerified] = useState(false)

  const formInvalid =
    formData.company === '' ||
    formData.registration === '' ||
    formData.business === '' ||
    formData.address === '' ||
    formData.area === '' ||
    formData.province === '' ||
    formData.contact === '' ||
    formData.email === '' ||
    verified === false

  const provinces = ['Gauteng', 'KwaZulu-Natal', 'Western Cape', 'Eastern Cape']
  const types = ['Private', 'Public']
  const areas = ['Cape Town', 'Durban', 'Johannesburg']

  const onSubmit = event => {
    event.preventDefault()

    return props.firebase
      .createCompany({
        ...formData,
        creatorId: props.authUser.uid,
      })
      .then(() => {
        resetForm()
      })
      .catch(error => {
        setError(error)
      })
  }

  const onChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onVerify = () => {
    setVerified(true)
  }

  const onExpired = () => {
    setVerified(false)
  }

  const onShow = () => {
    setShowModal(true)
  }

  const resetForm = () => {
    setFormData({
      company: '',
      registration: '',
      business: '',
      address: '',
      area: '',
      province: '',
      contact: '',
      email: '',
    })
    setVerified(false)
    setShowModal(false)
    recaptchaRef.current.reset()
  }

  return (
    <Fragment>
      <Modal
        title="Service Provider?"
        showModal={showModal}
        setShowModal={setShowModal}
        formInvalid={formInvalid}
        action="Sign Up"
        submitHandler={onSubmit}
      >
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label" htmlFor={'company'}>
                Company Name
              </label>
              <div className="control">
                <input
                  className="input"
                  name="company"
                  value={formData.company}
                  onChange={onChange}
                  type="text"
                  placeholder="Company Name"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'business'}>
                Type of Business
              </label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    name="business"
                    value={formData.business}
                    onChange={onChange}
                  >
                    <option>Select type</option>
                    {types.map(type => {
                      return <option key={type}>{type}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'area'}>
                Area
              </label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select name="area" value={formData.area} onChange={onChange}>
                    <option>Select area</option>
                    {areas.map(area => {
                      return <option key={area}>{area}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'contact'}>
                Contact No.
              </label>
              <div className="control">
                <input
                  className="input"
                  name="contact"
                  value={formData.contact}
                  onChange={onChange}
                  type="text"
                  placeholder="Contact No."
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label" htmlFor={'registration'}>
                Registration Number
              </label>
              <div className="control">
                <input
                  className="input"
                  name="registration"
                  value={formData.registration}
                  onChange={onChange}
                  type="text"
                  placeholder="Registration Number"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'address'}>
                Address
              </label>
              <div className="control">
                <input
                  className="input"
                  name="address"
                  value={formData.address}
                  onChange={onChange}
                  type="text"
                  placeholder="Address"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'province'}>
                Province
              </label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    name="province"
                    value={formData.province}
                    onChange={onChange}
                  >
                    <option>Select province</option>
                    {provinces.map(province => {
                      return <option key={province}>{province}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'email'}>
                Email Address
              </label>
              <div className="control">
                <input
                  className="input"
                  name="email"
                  value={formData.email}
                  onChange={onChange}
                  type="email"
                  placeholder="Email Address"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.GATSBY_RECAPTCHA_V2_SITE_KEY}
            onChange={onVerify}
            onExpired={onExpired}
          />
        </div>

        {error && <p>{error.message}</p>}
      </Modal>
      <button onClick={onShow} className="button is-link">
        Company Signup
      </button>
    </Fragment>
  )
}

export default withFirebase(CompanySignUp)
