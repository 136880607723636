import React, { Fragment } from 'react'

const CompanyList = () => {
  return (
    <Fragment>
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Component</p>
          <a
            href="https://www.99sense.co.za"
            className="card-header-icon"
            aria-label="more options"
          >
            <span className="icon">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </a>
        </header>
        <div className="card-content">
          <div className="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            nec iaculis mauris.
            <a href="https://www.99sense.co.za">@bulmaio</a>.{' '}
            <a href="https://www.99sense.co.za">#css</a>{' '}
            <a href="https://www.99sense.co.za">#responsive</a>
            <br />
            <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
          </div>
        </div>
        <footer className="card-footer">
          <a href="https://www.99sense.co.za" className="card-footer-item">
            Save
          </a>
          <a href="https://www.99sense.co.za" className="card-footer-item">
            Edit
          </a>
          <a href="https://www.99sense.co.za" className="card-footer-item">
            Delete
          </a>
        </footer>
      </div>
      <div className="card">
        <div className="card-image">
          <figure className="image is-4by3">
            <img
              src="https://bulma.io/images/placeholders/1280x960.png"
              alt="Placeholder"
            />
          </figure>
        </div>
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img
                  src="https://bulma.io/images/placeholders/96x96.png"
                  alt="Placeholder"
                />
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-4">John Smith</p>
              <p className="subtitle is-6">@johnsmith</p>
            </div>
          </div>
          <div className="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            nec iaculis mauris. <a href="https://www.99sense.co.za">@bulmaio</a>
            .<a href="https://www.99sense.co.za">#css</a>{' '}
            <a href="https://www.99sense.co.za">#responsive</a>
            <br />
            <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-image">
          <figure className="image is-500x500">
            <img src="http://via.placeholder.com/500x500" alt="Placeholder" />
          </figure>
        </div>
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img
                  src="https://bulma.io/images/placeholders/96x96.png"
                  alt="Placeholder"
                />
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-4">John Smith</p>
              <p className="subtitle is-6">@johnsmith</p>
            </div>
          </div>
          <div className="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            nec iaculis mauris. <a href="https://www.99sense.co.za">@bulmaio</a>
            .<a href="https://www.99sense.co.za">#css</a>{' '}
            <a href="https://www.99sense.co.za">#responsive</a>
            <br />
            <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CompanyList
