import React from 'react'
import GoogleMapReact from 'google-map-react'

const isClient = typeof window !== 'undefined'

const AnyReactComponent = ({ text }) => <div className="marker">{text}</div>

const MapForm = ({ address }) => {
  const { GOOGLEMAPS_API_KEY } = process.env
  const lat = parseFloat(address.lat)
  const lng = parseFloat(address.lng)
  const center = {
    lat: lat,
    lng: lng,
  }
  const zoom = 14

  return (
    <section className="google-map">
      <div className="map" style={{ height: '100vh', width: '100%' }}>
        {isClient && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLEMAPS_API_KEY || '' }}
            defaultCenter={center}
            defaultZoom={zoom}
          >
            <AnyReactComponent lat={lat} lng={lng} text="My Marker" />
          </GoogleMapReact>
        )}
      </div>
    </section>
  )
}

export default MapForm
