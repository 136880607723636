import React from 'react'

const SearchForm = () => {
  const onSubmit = e => {}

  const handleChange = e => {}

  return (
    <form onSubmit={e => onSubmit(e)}>
      <div className="field">
        <label className="label" htmlFor={'name'}>
          Search for service providers
        </label>
        <div className="control">
          <input
            className="input"
            type={'text'}
            name={'name'}
            onChange={e => handleChange(e)}
            id={'name'}
            required={true}
          />
        </div>
      </div>
      <div className="field">
        <button className="button is-link" type="submit">
          Send
        </button>
      </div>
    </form>
  )
}

export default SearchForm
