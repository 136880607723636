import React, { Fragment } from 'react'
import { compose } from 'recompose'

import Layout from '../../components/Layout'
import SearchForm from '../../components/Search'
import { CompanyList } from '../../components/Company'
import Map from '../../components/Map'
import {
  withAuthorization,
  withEmailVerification,
} from '../../components/Session'

const SearchPageBase = () => (
  <Fragment>
    <h1>Search Page</h1>
    <p>The Search Page is accessible by every signed in user.</p>
    <SearchForm />
    <div className="columns">
      <div className="column">
        <CompanyList />
      </div>
      <div className="column">
        <Map address={{ lat: 59.95, lng: 30.33 }} />
      </div>
    </div>
  </Fragment>
)

const condition = authUser => !!authUser

const SearchPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(SearchPageBase)

const Search = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <SearchPage />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Search
